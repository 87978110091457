<template>
  <div />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ActionTypes as AuthActions } from '@/store/auth/actions'

export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()

    store.dispatch(AuthActions.LOGOUT)
        .then(() => {
          router.push({ name: 'login' })
        })
    return {}
  },
})
</script>
